export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1590261442/general/cflogo.png'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1588575172/chickochicken/orderDeliveryButton.png'
export const aboutLangley =
  'https://docngc9nxic64.cloudfront.net/web/about_Langley.webp'
export const aboutSurrey =
  'https://docngc9nxic64.cloudfront.net/web/about_Surrey.webp'
export const aboutLougheed =
  'https://docngc9nxic64.cloudfront.net/web/about_Lougheed.webp'
export const aboutCoquitlamCenter =
  'https://docngc9nxic64.cloudfront.net/web/about_CoquitlamCenter.webp'
export const contactbg = 'https://docngc9nxic64.cloudfront.net/web/contact.webp'
export const downloadAppButton =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1611633022/sushicalifornia/orderDeliveryButton2.png'
export const foodGallery =
  'https://docngc9nxic64.cloudfront.net/web/foodGallery.webp'
export const heroOther =
  'https://docngc9nxic64.cloudfront.net/web/otherHero.webp'
export const locationMapLangley =
  'https://docngc9nxic64.cloudfront.net/web/locationMap_Langley.webp'
export const locationMapSurrey =
  'https://docngc9nxic64.cloudfront.net/web/locationMap_Surrey.webp'
export const locationMapLougheed =
  'https://docngc9nxic64.cloudfront.net/web/locationMap_lougheed.webp'
export const locationMapCoquitlamCenter =
  'https://docngc9nxic64.cloudfront.net/web/locationMap_coquitlamCenter.webp'
export const logo =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/logo.png'
export const logoBlack =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1606088359/sushicalifornia/logoBlack.png'
export const mobileAboutLangley =
  'https://docngc9nxic64.cloudfront.net/web/mobileAboutLangley.webp'
export const mobileAboutSurrey =
  'https://docngc9nxic64.cloudfront.net/web/mobileAboutSurrey.webp'
export const mobileAboutLougheed =
  'https://docngc9nxic64.cloudfront.net/web/mobileAboutLougheed.webp'
export const mobileAboutCoquitlamCenter =
  'https://docngc9nxic64.cloudfront.net/web/mobileAboutCoquitlamCenter.webp'
export const mobileContactbg =
  'https://docngc9nxic64.cloudfront.net/web/mobilecontactbg.webp'
export const mobileGallery =
  'https://docngc9nxic64.cloudfront.net/web/mobileGallery.webp'
export const mobileHeroOther =
  'https://docngc9nxic64.cloudfront.net/web/mobileHero.webp'
export const mobileMapLangley =
  'https://docngc9nxic64.cloudfront.net/web/mobileMap_Langley.webp'
export const mobileMapSurrey =
  'https://docngc9nxic64.cloudfront.net/web/mobileMap_Surrey.webp'
export const mobileMapLougheed =
  'https://docngc9nxic64.cloudfront.net/web/mobileMap_lougheed.webp'
export const mobileMapCoquitlamCenter =
  'https://docngc9nxic64.cloudfront.net/web/mobileMap_coquitlamCenter.webp'
export const orderPickupButton =
  'https://res.cloudinary.com/assetcodefusion/image/upload/f_auto,q_auto:best/v1611633022/sushicalifornia/orderPickupButton2.png'
