import React from 'react'
import {
  CFImage,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  mobileAboutLangley,
  mobileAboutSurrey,
  mobileAboutLougheed,
  mobileAboutCoquitlamCenter,
  aboutLangley,
  aboutSurrey,
  aboutLougheed,
  aboutCoquitlamCenter,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let about = null
        let mobileAbout = null
        if (locationId === 'XuqSDpeKcOIQc5ULSbcZ') {
          about = aboutLangley
          mobileAbout = mobileAboutLangley
        } else if (locationId === 'RDz3ejnYYrMEgoSM6tKi') {
          about = aboutSurrey
          mobileAbout = mobileAboutSurrey
        } else if (locationId === 'fwizLHhySIAZPZ7jnERj') {
          about = aboutLougheed
          mobileAbout = mobileAboutLougheed
        } else {
          about = aboutCoquitlamCenter
          mobileAbout = mobileAboutCoquitlamCenter
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage src={mobileAbout} w="100%" alt="About" />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center maxWidth="1400px">
                <CFImage src={about} w="100%" alt="About1" />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
