import React from 'react'
import {
  CFView,
  CFImage,
  CFLink,
  CFSelect,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  downloadAppButton,
  heroMelville,
  heroBroadway,
  heroOther,
  logo,
  mobileHeroBroadway,
  mobileHeroMelville,
  mobileHeroOther,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHeroOther}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          center
        >
          <CFView w="100%" column center mb="35px">
            <CFImage
              h="85px"
              mt="35px"
              src={logo}
              alt="Sushi California Logo"
              zIndex={98}
            />
            <LocationSelect />
          </CFView>
          <CFView column justifyBetween alignCenter ph="10px" mb="10%">
            <CFView mb="10px" pulsate>
              <OrderPickupButton />
            </CFView>
            {/* <CFLink href="http://onelink.to/sushicalifornia">
              <CFImage
                src={downloadAppButton}
                maxWidth="240px"
                alt="Sushi California Download App"
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          maxHeight="690px"
          image={`url(${heroOther}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
          zIndex={90}
          column
          center
        >
          <CFImage src={logo} w="40%" alt="Promotions" />
          <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
            <CFView mt="5px" mb="35px" zIndex={99}>
              <LocationSelect />
            </CFView>
          </CFSelect>
          <CFView w="100%" column center>
            <CFView pulsate mb="10px">
              <OrderPickupButton />
            </CFView>
            {/* <CFLink href="http://onelink.to/sushicalifornia">
              <CFImage
                src={downloadAppButton}
                maxWidth="240px"
                alt="Sushi California Download App"
                hover
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
