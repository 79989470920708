import React, { useState, useEffect } from 'react'
import { css } from 'emotion'
import { CFView, PrimaryModal } from 'components'
import { dispatch } from 'store'

export default () => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      const welcomeMessage = dispatch.restaurant.getWelcomeMessage()
      if (welcomeMessage.title) {
        setShowModal(true)
      }
    }, 1000)
  }, [])

  const closeModal = () => {
    setShowModal(false)
    // localStorage.setItem('welcomeSeen', true)
  }

  return (
    <PrimaryModal
      title={dispatch.restaurant.getWelcomeMessage().title}
      isOpen={showModal}
      onRequestClose={closeModal}
    >
      <CFView className={styles.container}>
        <CFView column center textCenter w="100%">
          <CFView h4>{dispatch.restaurant.getWelcomeMessage().body}</CFView>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '66rem',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  }),
}
