import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapLangley,
  locationMapSurrey,
  locationMapLougheed,
  locationMapCoquitlamCenter,
  mobileMapLangley,
  mobileMapSurrey,
  mobileMapLougheed,
  mobileMapCoquitlamCenter,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'XuqSDpeKcOIQc5ULSbcZ') {
          locationLink = 'https://goo.gl/maps/JEecUZ7CQzz59Pxu7'
          mapImage = locationMapLangley
          mapImageMobile = mobileMapLangley
        } else if (locationId === 'RDz3ejnYYrMEgoSM6tKi') {
          locationLink = 'https://goo.gl/maps/CyAat6iz1Vq21Lno6'
          mapImage = locationMapSurrey
          mapImageMobile = mobileMapSurrey
        } else if (locationId === 'fwizLHhySIAZPZ7jnERj') {
          locationLink = 'https://goo.gl/maps/pTEJre2HoW1Lmy386'
          mapImage = locationMapLougheed
          mapImageMobile = mobileMapLougheed
        } else {
          locationLink = 'https://goo.gl/maps/NM5MN1wEwWDzvrT57'
          mapImage = locationMapCoquitlamCenter
          mapImageMobile = mobileMapCoquitlamCenter
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage
                  src={mapImageMobile}
                  w="100%"
                  alt="Sushi California Map"
                />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImage} w="100%" alt="Sushi California Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
